.base-top__pic {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.c-photo__main {
  position: relative;
  width: 100%;
  transform: translate3d(0px, 0, 0);
}
.c-photo__main__list {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
}
.c-photo__main__list__item {
  width: 100%;
  height: 400px;
  background: #eee;
  flex-shrink: 0;
  transition: transform 500ms;
}
.c-photo__main__list__item img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-photo__main__prev,
.c-photo__main__next {
  cursor: pointer;
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  z-index: 10;
  background: #fff;
  background: var(--theme-content-section-background-color);
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 100%;
}
.c-photo__main__prev {
  left: 8px;
}
.c-photo__main__next {
  transform: scale(-1, 1);
  right: 8px;
}
.c-photo__main__prev::after,
.c-photo__main__next::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  border-left: 2px solid #0094cc;
  border-left: 2px solid var(--theme-primary-color);
  border-bottom: 2px solid #0094cc;
  border-bottom: 2px solid var(--theme-primary-color);
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 5px);
  left: 13px;
}
