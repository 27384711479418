.c-title {
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-bottom: 1px solid #ddd;
  padding: 40px 16px;
  display: flex;
  justify-content: center;
}
.c-title h2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-title img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}
@media screen and (max-width: 768px) {
  .c-title {
    padding: 20px 16px;
  }
  .c-title h2 {
    font-size: 16px;
  }
  .c-title img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
}
